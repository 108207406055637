// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import 'firebase/compat/database'
import 'firebase/compat/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAI0yrgwthmz-cFy7egJGszCMrQLCe5mlc",
  authDomain: "agora-pertuk.firebaseapp.com",
  databaseURL: "https://agora-pertuk-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "agora-pertuk",
  storageBucket: "agora-pertuk.appspot.com",
  messagingSenderId: "1015299704165",
  appId: "1:1015299704165:web:1f0235cfa5fb2ab290cd8c",
  measurementId: "G-BRY4FPJYBX"
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);


