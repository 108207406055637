<template>
  <div>
    <div class="banner agora-gold text-white flex items-center px-10 font-bold">
        {{ $t("Section.Home") }}<i v-if="getCurrentDir=='ltr'" class="fa fa-angles-right px-3"></i><i v-if="getCurrentDir=='rtl'" class="fa fa-angles-left px-3"></i>{{ $t("Section.FaqTitle") }}
    </div>
    <br />
    <div id="FAQ" class="FAQ">
      <div class="wrapper my-2">
        <h2 class="text-3xl font-bold mb-4">
          {{ $t("Section.FaqTitle") }}
        </h2>
        <br />
        <div class="accordion">
          <div
            v-for="(item, index) in filteredFaqs"
            :key="item.id"
            @click="toggleAccordion(index)"
            class="accordion-item border rounded-md mb-2"
          >
            <div
              class="accordion-title cursor-pointer px-4 py-2 flex justify-between items-center"
            >
              <div
                v-html="item['Title' + Lang]"
                class="siteColor text-lg font-bold py-3"
              ></div>
              <svg
                class="w-4 h-4 transform transition-transform duration-300"
                :class="{ 'rotate-180': activeIndex === index }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path fill="currentColor" d="M7 10l5 5 5-5z" />
              </svg>
            </div>
            <div
              v-show="activeIndex === index"
              class="accordion-content px-4 py-2"
            >
              <div v-html="item['Content' + Lang]" class="py-3"></div>
            </div>
          </div>
        </div>
        <br />
        
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: mapGetters([
    "getCurrentLocale",
    "getCurrentDir",
    "getBooks",
    "getFaqs",
    "Lang",
  ]),
  data(){
    return{
        filteredFaqs: null,
        activeIndex: null,
    }
  },
  methods: {
    toggleAccordion(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null;
      } else {
        this.activeIndex = index;
      }
    },
  },
  mounted(){
    let faqTry = 0;
   

    let faqFillInterval = setInterval(() => {
      this.filteredFaqs = this.getFaqs.filter((item) => {
        return item.Enabled;
      });

      if (this.filteredFaqs.length > 0) {
        clearInterval(faqFillInterval);
      } else {
        faqTry++;
        if (faqTry > 11) {
          clearInterval(faqFillInterval);
        }
        console.log("Retrieving faqs . . .");
      }

      this.$loading(false);
    }, 500);

    
     
  }
}
</script>

<style>

</style>