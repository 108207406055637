<style scoped>
table {
  width: 100%;
  display: table;
}
th,
td {
  border: 1px solid gray;
  padding: 10px 15px;
  overflow: hidden;
}
</style>
<template>
  <div id="Faqs" class="px-5 py-3 dashboard">
    <!-- component -->
    <!-- This is an example component -->

    <!-- component -->
    <button
      class="btn text-white rounded-md px-4 py-2 hover:bg-blue-700 transition"
      @click="openModal('modal')"
    >
      Create New
    </button>

    <div
      id="modal"
      class="modal fixed hidden z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4"
    >
      <div
        class="relative top-10 mx-auto shadow-lg rounded-md bg-white max-w-md"
      >
        <!-- Modal header -->
        <div
          class="flex justify-between items-center agora-gold text-white text-xl rounded-t-md px-4 py-2"
        >
          <h3>New Faq</h3>
          <button @click="closeModal()">x</button>
        </div>

        <!-- Modal body -->
        <div class="p-4">
          <form @submit.prevent="createNew">
            <div class="bothLangs">
              <div class="Kurdish">
                Kurdish
                <br />
                <br />
                Type of faq:

                <br />
                <label for="Title"
                  >Title
                  <br />
                  <input
                    type="text"
                    name="Title"
                    class="border"
                    v-model="newFaq.TitleKU"
                  />
                </label>

                <br />
                <label for="Content"
                  >Content
                  <br />
                  <vue-editor v-model="newFaq.ContentKU"></vue-editor>
                </label>
                <br />
              </div>
              <div class="Kirmanji">
                Kirmanji
                <br />
                <br />
                Type of faq:

                <br />
                <label for="Title"
                  >Title
                  <br />
                  <input
                    type="text"
                    name="Title"
                    class="border"
                    v-model="newFaq.TitleKR"
                  />
                </label>

                <br />
                <label for="Content"
                  >Content
                  <br />
                  <vue-editor v-model="newFaq.ContentKR"></vue-editor>
                </label>
                <br />
              </div>
            </div>
            <label for="Enabled"
              >Enabled <br />
              <input type="checkbox" v-model="newFaq.Enabled" />
            </label>

            <br />

            <br />
            <br />
            <button class="btn px-3 py-2 rounded-md text-white" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
    <div
      id="editModal"
      class="modal fixed hidden z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4"
    >
      <div
        class="relative top-10 mx-auto shadow-lg rounded-md bg-white max-w-md"
      >
        <!-- Modal header -->
        <div
          class="flex justify-between items-center agora-gold text-white text-xl rounded-t-md px-4 py-2"
        >
          <h3>Edit Faq</h3>
          <button @click="closeModal()">x</button>
        </div>

        <!-- Modal body -->
        <div class="p-4">
          <form @submit.prevent="updateFaq(newFaq.id)">
            <div class="bothLangs">
              <div class="Kurdish">
                Kurdish
                <br />
                <br />
                Type of faq:
                <br />
                <label for="Title"
                  >Title
                  <br />
                  <input
                    type="text"
                    name="Title"
                    class="border"
                    v-model="newFaq.TitleKU"
                  />
                </label>
                <br />

                <label for="Content"
                  >Content
                  <br />
                  <vue-editor v-model="newFaq.ContentKU"></vue-editor>
                </label>
                <br />
              </div>
            </div>
            <div class="Kirmanji">
              Kirmanji
              <br />
              <br />
              Type of faq:

              <br />
              <label for="Title"
                >Title
                <br />
                <input
                  type="text"
                  name="Title"
                  class="border"
                  v-model="newFaq.TitleKR"
                />
              </label>

              <br />
              <label for="Content"
                >Content
                <br />
                <vue-editor v-model="newFaq.ContentKR"></vue-editor>
              </label>
              <br />
            </div>
            <input type="hidden" name="id" v-model="newFaq.id" />
            <label for="Enabled"
              >Enabled <br />
              <input type="checkbox" v-model="newFaq.Enabled" />
            </label>
            <br />

            <br />
            <br />
            <button class="btn px-3 py-2 rounded-md text-white">Submit</button>
          </form>
        </div>
      </div>
    </div>
    <br /><br />
    <div class="mx-auto">
      <div class="flex flex-col">
        <div class="overflow-x-auto shadow-md sm:rounded-lg">
          <div class="inline-block min-w-full align-middle">
            <div class="overflow-hidden">
              <table
                class="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700"
              >
                <thead class="bg-gray-100 dark:bg-gray-700">
                  <tr>
                    <th scope="col" class="p-4">
                      <div class="flex items-center">ID</div>
                    </th>
                    <th
                      scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
                    >
                      Title
                    </th>

                    <th
                      scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
                    >
                      Enabled
                    </th>
                    <th scope="col" class="p-4">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody
                  class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700"
                >
                  <tr
                    class="hover:bg-gray-100 dark:hover:bg-gray-700"
                    v-for="p in getFaqs"
                    :key="p.id"
                  >
                    <td class="p-4 w-4">
                      <div class="flex items-center">
                        {{ p.id }}
                      </div>
                    </td>
                    <td
                      class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {{ p["Title" + Lang] }}
                    </td>

                    <td
                      class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {{ p.Enabled }}
                    </td>
                    <td
                      class="py-4 px-6 text-sm font-medium text-right whitespace-nowrap"
                    >
                      <button
                        class="bg-yellow-400 text-white font-bold px-3 py-2 rounded-md"
                        @click="
                          openModal('editModal');
                          fillInfo(p.id);
                        "
                      >
                        Edit
                      </button>
                      |
                      <button
                        class="bg-red-600 text-white font-bold px-3 py-2 rounded-md"
                        @click="deleteFaq(p.id)"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { mapGetters } from "vuex";
export default {
  computed: mapGetters(["getFaqs", "Lang"]),
  components: {
    VueEditor,
  },
  data() {
    return {
      faqId: null,
      img: null,
      uploadValue: null,
      imageData: null,
      caption: "",

      newFaq: {
        TitleKU: null,
        ContentKU: null,

        TitleKR: null,
        ContentKR: null,

        Enabled: false,
      },
      faqs: [],
    };
  },
  methods: {
    openModal(modalId) {
      let modal = document.getElementById(modalId);
      modal.classList.remove("hidden");
    },
    closeModal() {
      let modals = document.getElementsByClassName("modal");
      for (let i = 0; i < modals.length; i++) {
        modals[i].classList.add("hidden");
      }
    },
    fillInfo(id) {
      let filteredFaqs = this.getFaqs.filter((item) => {
        return item.id == id;
      });
      this.newFaq = filteredFaqs[0];
    },
    createNew() {
      console.log("hit");
      this.newFaq.img = this.img;

      this.$store.commit("addFaq", this.newFaq);
      this.img = null;
      this.newFaq = {
        TitleKU: null,
        ContentKU: null,

        TitleKR: null,
        ContentKR: null,

        Enabled: false,
      };
      this.closeModal();
    },
    updateFaq() {
      if (this.img) {
        console.log("Will upload");
        this.newFaq.img = this.img;
      }
      this.$store.commit("updateFaq", {
        id: this.newFaq.id,
        data: this.newFaq,
      });
      this.newFaq = {
        TitleKU: null,
        ContentKU: null,

        TitleKR: null,
        ContentKR: null,

        Enabled: false,
      };
      this.closeModal();
    },
    deleteFaq(id) {
      this.$store.commit("deleteFaq", id);
    },
  },

  mounted() {},
};
</script>

<style>
</style>