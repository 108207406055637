<template>
  <div id="sideBar">
    <div class="py-3">
      <img src="/Images/logo-white.png" alt="Logo" />
    </div>
    <br />
    <ul>
      
      <li>
        <router-link to="faqs">
          <div
            class="
              transition
              text-center text-xs text-gray-100
              py-3
              hover:bg-gray-100 hover:text-gray-900
            "
          >
            <i class="text-lg  fa fa-question"></i>
            <br />
            <span>FAQ</span>
          </div>
        </router-link>
      </li>
      <li>
        <router-link to="books">
          <div
            class="
              transition
              text-center text-xs text-gray-100
              py-3
              hover:bg-gray-100 hover:text-gray-900
            "
          >
            <i class="text-lg  fa fa-book"></i>
            <br />
            <span>Books</span>
          </div>
        </router-link>
      </li>
     
      <li>
        <a @click="logOut">
          <div
            class="
              transition
              text-center text-xs text-gray-100
              py-3
              hover:bg-gray-100 hover:text-gray-900
            "
          >
          <i class=" text-lg fa-solid fa-arrow-right-from-bracket"></i>
           
            <br />
            <span>log out</span>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  methods:{
    logOut(){
      this.$store.dispatch("logOut")
    }
  }
};
</script>

<style>
</style>