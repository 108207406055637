<template>
  <div
    id="app"
    :data-route="$route.path.substring(1, 10)"
    :class="getCurrentDir"
    :dir="getCurrentDir"
    :style="{
      direction: getCurrentDir,
      'text-align': getCurrentDir == 'rtl' ? 'right' : 'left',
    }"
  >
    <div class="menu" :class="{ toggled: toggled }">
      <ul>
        <li>
          <a @click="toggled = false" href="/"> {{
            $t("Section.Home")
          }} </a>
        </li>
        <li>
          <a @click="toggled = false" href="/#About">{{
            $t("Section.About")
          }}</a>
        </li>

        <li>
          <a @click="toggled = false" href="/#FAQ">{{
            $t("Section.FaqTitle")
          }}</a>
        </li>
        <li>
          <a @click="toggled = false" href="/#Contact">{{
            $t("Section.ContactTitle")
          }}</a>
        </li>
        <li @click="toggled = false">
          <div class="px-3 siteColor">
            <span class="cursor-pointer" v-if="getCurrentDir == 'rtl'">
              <a
                @click="switchLocale('kr')"
                :key="'kr'"
                style="font-family: 'Speda' !important"
                >{{ $t("Language.Kirmanji") }}</a
              >
            </span>

            <span class="cursor-pointer" v-if="getCurrentDir == 'ltr'">
              <a
                @click="switchLocale('ku')"
                :key="'ku'"
                style="font-family: 'Speda' !important"
                >{{ $t("Language.Kurdish") }}</a
              >
            </span>
          </div>
        </li>
      </ul>
    </div>
    <nav>
      <div
        class="logo grid font-bold"
        style="grid-template-columns: auto auto auto"
      >
        <router-link to="/">
          <img src="/Images/logo.png" alt="Logo" />
        </router-link>
        <ul
          class="desktopNav text-white hidden gap-10 lg:flex items-center text-lg"
          :class="{
            'ml-5 pl-5': getCurrentDir == 'ltr',
            'mr-5 pr-5': getCurrentDir == 'rtl',
          }"
          :style="{ 'line-height': getCurrentDir == 'rtl' ? '36px' : '30px' }"
        >
          <li>
            <a @click="toggled = false" href="/">{{
            $t("Section.Home")
          }}</a>
          </li>
          <li>
            <a @click="toggled = false" href="/#About">{{
            $t("Section.About")
          }}</a>
          </li>

          <li>
            <a @click="toggled = false" href="/#FAQ">{{
            $t("Section.FaqTitle")
          }}</a>
          </li>
          <li>
            <a @click="toggled = false" href="/#Contact">{{
            $t("Section.ContactTitle")
          }}</a>
          </li>
          <li>
            <div class="flex h-full px-3 siteColor items-center">
              <span class="cursor-pointer" v-if="getCurrentDir == 'rtl'">
                <a
                  @click="switchLocale('kr')"
                  :key="'kr'"
                  style="font-family: 'Speda' !important"
                  >{{ $t("Language.Kirmanji") }}</a
                >
              </span>

              <span class="cursor-pointer" v-if="getCurrentDir == 'ltr'">
                <a
                  @click="switchLocale('ku')"
                  :key="'ku'"
                  style="font-family: 'Speda' !important"
                  >{{ $t("Language.Kurdish") }}</a
                >
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div class="grid font-bold" style="grid-template-columns: auto auto">
        <div
          class="hamburger block lg:hidden"
          :class="{ toggled: toggled }"
          @click="toggled = !toggled"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </nav>
    <sideBar />
    <router-view
      :class="getCurrentDir"
      :dir="getCurrentDir"
      :style="{
        direction: getCurrentDir,
        'text-align': getCurrentDir == 'rtl' ? 'right' : 'left',
      }"
    />

    <footer class="block">
      <div></div>
      <div class="social mx-auto">
        <div class="text-2xl">
          <a
            href="https://www.facebook.com/agoravisioncom"
            target="_blank"
            rel="noopener noreferrer"
            ><i class="fab fa-facebook"></i
          ></a>
          <a
            href="https://www.instagram.com/agoravisioncom/"
            target="_blank"
            rel="noopener noreferrer"
            ><i class="fab fa-instagram"></i
          ></a>
          <a
            href="https://www.linkedin.com/company/agoravision/"
            target="_blank"
            rel="noopener noreferrer"
            ><i class="fab fa-linkedin"></i
          ></a>
          <a
            href="https://www.youtube.com/channel/UCcGXMcvYue1apphdDW4jtZA/featured"
            target="_blank"
            rel="noopener noreferrer"
            ><i class="fab fa-youtube"></i
          ></a>
          <a
            href="https://web.telegram.org/k/#/im?p=@agoravision"
            target="_blank"
            rel="noopener noreferrer"
            ><i class="fab fa-telegram"></i
          ></a>
        </div>
      </div>
      <p class="text-center font-bold">
        <span v-html="$t('Copyright')"></span>
      </p>
    </footer>
    <div
      id="backToTop"
      class="rounded-md shadow-lg font-bold text-xl text-blue-500 text-center"
    >
      <a href="#app"><i class="fa fa-caret-up"></i></a>
    </div>
  </div>
</template>
<script>
import sideBar from "./components/sidebar.vue";
import { mapGetters } from "vuex";
export default {
  computed: mapGetters(["getCurrentLocale", "getCurrentDir", "getUser"]),
  components: {
    sideBar,
  },
  data() {
    return {
      toggled: false,
    };
  },

  mounted() {
    console.log(this.$store.getters.getCurrentLocale);
  },
  methods: {
    saveLocale() {
      sessionStorage.setItem("locale", this.$store.getters.getCurrentLocale);
    },
    switchLocale(newLocale) {
      this.$store.commit("changeLocale", newLocale);
      this.$i18n.locale = newLocale;
      this.toggled = false;
      document.querySelector("body").removeAttribute("class");
      document.querySelector("body").classList.add(this.getCurrentDir);
      setTimeout(() => {}, 300);
    },
  },
};
</script>
<style>
@media (max-width: 520px) {
  .grid.font-bold a {
    font-size: 12px;
  }
}
@media (max-width: 400px) {
  .grid.font-bold a {
    font-size: 10px;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
