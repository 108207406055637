import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import Login from '../views/Dashboard/Login.vue'
import Books from '../views/Dashboard/Books.vue'
import Faqs from '../views/Dashboard/Faqs.vue'
import faq from '../views/FAQ.vue'



import guard from './auth-guard'
import notFound from '../views/404.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    
    component: notFound,

  },
  {
    path: '/',
    name: 'home',
    component: Home,

  },
  
  {
    path: '/faq',
    name: 'faq',
    component: faq,

  },
  {
    path: '/prsyarekan',
    name: 'prsyarekan',
    component: faq,

  },
  {
    path: '/login',
    name: "login",
    component: Login
  },
  {
    path: '/dashboard/books',
    name: "books",
    component: Books,
    beforeEnter: guard
  }
 ,
  {
    path: '/dashboard/faqs',
    name: "faqs",
    component: Faqs,
    beforeEnter: guard
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router