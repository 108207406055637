<style scoped>
table {
  width: 100%;
  display: table;
}
th,
td {
  border: 1px solid gray;
  padding: 10px 15px;
  overflow: hidden;
}
</style>
<template>
  <div id="Books" class="px-5 py-3 dashboard">
    <!-- component -->
    <!-- This is an example component -->

    <!-- component -->
    <button
      class="btn text-white rounded-md px-4 py-2 hover:bg-blue-700 transition"
      @click="openModal('modal')"
    >
      Create New
    </button>

    <div
      id="modal"
      class="
        modal
        fixed
        hidden
        z-50
        inset-0
        bg-gray-900 bg-opacity-60
        overflow-y-auto
        h-full
        w-full
        px-4
      "
    >
      <div
        class="relative top-10 mx-auto shadow-lg rounded-md bg-white max-w-md"
      >
        <!-- Modal header -->
        <div
          class="
            flex
            justify-between
            items-center
            agora-gold
            text-white text-xl
            rounded-t-md
            px-4
            py-2
          "
        >
          <h3>New Book</h3>
          <button @click="closeModal()">x</button>
        </div>

        <!-- Modal body -->
        <div class="p-4">
          <form @submit.prevent="createNew">
            <div class="bothLangs" >
              <div class="Kurdish">
                Kurdish
                <br>
                <br>
                Type of book:
               
                <br>
                <label for="Title"
                  >Title
                  <br />
                  <input
                    type="text"
                    name="Title"
                    class="border"
                    v-model="newBook.TitleKU"
                  />
                </label>
               
                <br />
                <label for="Author"
                  >Author
                  <br />
                  <input
                    type="text"
                    name="Title"
                    class="border"
                    v-model="newBook.AuthorKU"
                  />
                </label>
                <br />
              </div>
              <div class="Kirmanji">
                Kirmanji
                <br>
                <br>
                Type of book:
               
                <br>
                <label for="Title"
                  >Title
                  <br />
                  <input
                    type="text"
                    name="Title"
                    class="border"
                    v-model="newBook.TitleKR"
                  />
                </label>
                <br />
                
                <label for="Author"
                  >Author
                  <br />
                  <input
                    type="text"
                    name="Title"
                    class="border"
                    v-model="newBook.AuthorKR"
                  />
                </label>
                <br />
              </div>
              
            </div>
            <label for="Enabled"
              >Enabled <br />
              <input type="checkbox" v-model="newBook.Enabled" />
            </label>
            
            <br />

            <label for="ImageUrl"
              >Image
              <br />
             
              <input
                type="file"
                name="ImageUrl"
                class="hidden"
                ref="imageUploadBtn"
                @change="previewImage"
                accept="image/jpg"
              />
              <button
                class="btn px-3 py-2 rounded-md text-white"
                @click="uploadTrigger"
              >
                Upload Image
              </button>
              <br />
            </label>
            <br />
            <div v-if="imageData != null">
              <img class="preview" height="268" width="356" :src="img" />
              <br />
            </div>
            
            <br />
            <br />
            <button class="btn px-3 py-2 rounded-md text-white" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
    <div
      id="editModal"
      class="
        modal
        fixed
        hidden
        z-50
        inset-0
        bg-gray-900 bg-opacity-60
        overflow-y-auto
        h-full
        w-full
        px-4
      "
    >
      <div
        class="relative top-10 mx-auto shadow-lg rounded-md bg-white max-w-md"
      >
        <!-- Modal header -->
        <div
          class="
            flex
            justify-between
            items-center
            agora-gold
            text-white text-xl
            rounded-t-md
            px-4
            py-2
          "
        >
          <h3>Edit Book</h3>
          <button @click="closeModal()">x</button>
        </div>

        <!-- Modal body -->
        <div class="p-4">
          <form @submit.prevent="updateBook(newBook.id)">
            <div class="bothLangs" >
              <div class="Kurdish">
                Kurdish
                <br>
                <br>
                Type of book:
               <br>
                <label for="Title"
                  >Title
                  <br />
                  <input
                    type="text"
                    name="Title"
                    class="border"
                    v-model="newBook.TitleKU"
                  />
                </label>
                
                <br />
                <label for="Author"
                  >Author
                  <br />
                  <input
                    type="text"
                    name="Title"
                    class="border"
                    v-model="newBook.AuthorKU"
                  />
                </label>
                <br />
              </div>
              <div class="Kirmanji">
                Kirmanji
                <br>
                <br>
                Type of book:
               
                <br>
                <label for="Title"
                  >Title
                  <br />
                  <input
                    type="text"
                    name="Title"
                    class="border"
                    v-model="newBook.TitleKR"
                  />
                </label>
                <br />
                
                <label for="Author"
                  >Author
                  <br />
                  <input
                    type="text"
                    name="Title"
                    class="border"
                    v-model="newBook.AuthorKR"
                  />
                </label>
                <br />
              </div>
            </div>
            <input type="hidden" name="id" v-model="newBook.id" />
            <label for="Enabled"
              >Enabled <br />
              <input type="checkbox" v-model="newBook.Enabled" />
            </label>
            
            <br />
            
            <label for="ImageUrl"
              >Image
              <br />
              
              <input
                type="file"
                name="ImageUrl"
                class="hidden"
                ref="imageUploadBtn"
                @change="previewImage"
                accept="image/jpg"
              />
              <button
                class="btn px-3 py-2 rounded-md text-white"
                @click="uploadTrigger"
              >
                Upload Image
              </button>
              <br />
              <div v-if="imageData != null">
                <img class="preview" height="268" width="356" :src="img" />
                <br />
              </div>
            </label>
            
            <br />
            <br />
            <button class="btn px-3 py-2 rounded-md text-white">Submit</button>
          </form>
        </div>
      </div>
    </div>
    <br /><br />
    <div class="mx-auto">
      <div class="flex flex-col">
        <div class="overflow-x-auto shadow-md sm:rounded-lg">
          <div class="inline-block min-w-full align-middle">
            <div class="overflow-hidden">
              <table
                class="
                  min-w-full
                  divide-y divide-gray-200
                  table-fixed
                  dark:divide-gray-700
                "
              >
                <thead class="bg-gray-100 dark:bg-gray-700">
                  <tr>
                    <th scope="col" class="p-4">
                      <div class="flex items-center">ID</div>
                    </th>
                    <th
                      scope="col"
                      class="
                        py-3
                        px-6
                        text-xs
                        font-medium
                        tracking-wider
                        text-left text-gray-700
                        uppercase
                        dark:text-gray-400
                      "
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      class="
                        py-3
                        px-6
                        text-xs
                        font-medium
                        tracking-wider
                        text-left text-gray-700
                        uppercase
                        dark:text-gray-400
                      "
                    >
                      Author
                    </th>
                    
                    <th
                      scope="col"
                      class="
                        py-3
                        px-6
                        text-xs
                        font-medium
                        tracking-wider
                        text-left text-gray-700
                        uppercase
                        dark:text-gray-400
                      "
                    >
                      Enabled
                    </th>
                    <th scope="col" class="p-4">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody
                  class="
                    bg-white
                    divide-y divide-gray-200
                    dark:bg-gray-800 dark:divide-gray-700
                  "
                >
                  <tr
                    class="hover:bg-gray-100 dark:hover:bg-gray-700"
                    v-for="p in getBooks"
                    :key="p.id"
                  >
                    <td class="p-4 w-4">
                      <div class="flex items-center">
                        {{ p.id }}
                      </div>
                    </td>
                    <td
                      class="
                        py-4
                        px-6
                        text-sm
                        font-medium
                        text-gray-900
                        whitespace-nowrap
                        dark:text-white
                      "
                    >
                      {{ p['Title'+Lang] }}
                    </td>
                    <td
                      class="
                        py-4
                        px-6
                        text-sm
                        font-medium
                        text-gray-900
                        whitespace-nowrap
                        dark:text-white
                      "
                    >
                      {{ p['Author'+Lang] }}
                    </td>
                  
                    <td
                      class="
                        py-4
                        px-6
                        text-sm
                        font-medium
                        text-gray-900
                        whitespace-nowrap
                        dark:text-white
                      "
                    >
                      {{ p.Enabled }}
                    </td>
                    <td
                      class="
                        py-4
                        px-6
                        text-sm
                        font-medium
                        text-right
                        whitespace-nowrap
                      "
                    >
                      <button
                        class="
                          bg-yellow-400
                          text-white
                          font-bold
                          px-3
                          py-2
                          rounded-md
                        "
                        @click="
                          openModal('editModal');
                          fillInfo(p.id);
                        "
                      >
                        Edit
                      </button>
                      |
                      <button
                        class="
                          bg-red-600
                          text-white
                          font-bold
                          px-3
                          py-2
                          rounded-md
                        "
                        @click="deleteBook(p.id)"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { app } from "../../firebase";

import {
  getStorage,
  ref as stRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import { mapGetters } from "vuex";
export default {
  computed: mapGetters(["getBooks","Lang"]),
 
  data() {
    return {
      bookId: null,
      img: null,
      uploadValue: null,
      imageData: null,
      caption: "",

      newBook: {
        TitleKU: null,
        AuthorKU: null,
        
        TitleKR: null,
        AuthorKR: null,
        


       
        ImageUrl: null,
        JpgUrl: null,
        
        Enabled: false,
        img: null,
        jpg:null,
        
        tags:null
        
      },
      books: [],
    };
  },
  methods: {
    uploadTrigger(e) {
      e.preventDefault();
      this.$refs.imageUploadBtn.click();
    },
    previewImage(event) {
      this.uploadValue = 0;
      this.img = null;
      this.imageData = event.target.files[0];
      this.onUpload();
    },
    onUpload() {
      this.$loading(true);
      let bytes = this.imageData;

      //================================================
      const storage = getStorage(app);
      const storageRef = stRef(storage, "files/" + bytes.name);
      const uploadTask = uploadBytesResumable(storageRef, bytes);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log("Upload has an error: " + error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            this.img = downloadURL;
          });
          this.$loading(false);
        }
      );
      //================================================
    },
    openModal(modalId) {
      let modal = document.getElementById(modalId);
      modal.classList.remove("hidden");
    },
    closeModal() {
      let modals = document.getElementsByClassName("modal");
      for (let i = 0; i < modals.length; i++) {
        modals[i].classList.add("hidden");
      }
    },
    fillInfo(id) {
      let filteredBooks = this.getBooks.filter((item) => {
        return item.id == id;
      });
      this.newBook = filteredBooks[0];
    },
    createNew() {
      this.newBook.img = this.img;

      this.$store.commit("addBook", this.newBook);
      this.img = null;
      this.newBook = {
        TitleKU: null,
        AuthorKU: null,
        

        TitleKR: null,
        AuthorKR: null,
        

      

       
        ImageUrl: null,
        JpgUrl: null,
        
        Enabled: false,
        img: null,
        jpg:null,
        
        tags:null
      };
      this.closeModal();
    },
    updateBook() {
      if (this.img) {
        console.log("Will upload");
        this.newBook.img = this.img;
      }
      this.$store.commit("updateBook", {
        id: this.newBook.id,
        data: this.newBook,
      });
      this.newBook = {
        TitleKU: null,
        AuthorKU: null,
        

        TitleKR: null,
        AuthorKR: null,
        

       

        
        ImageUrl: null,
        JpgUrl: null,
        
        Enabled: false,
        img: null,
        jpg:null,
        
        tags:null
      };
      this.closeModal();
    },
    deleteBook(id) {
      this.$store.commit("deleteBook", id);
    },
  },

  mounted() {
    
  },
};
</script>

<style>
</style>