var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.getCurrentDir,style:({
    direction: _vm.getCurrentDir,
    'text-align': _vm.getCurrentDir == 'rtl' ? 'right' : 'left',
  }),attrs:{"id":"app","data-route":_vm.$route.path.substring(1, 10),"dir":_vm.getCurrentDir}},[_c('div',{staticClass:"menu",class:{ toggled: _vm.toggled }},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"/"},on:{"click":function($event){_vm.toggled = false}}},[_vm._v(" "+_vm._s(_vm.$t("Section.Home"))+" ")])]),_c('li',[_c('a',{attrs:{"href":"/#About"},on:{"click":function($event){_vm.toggled = false}}},[_vm._v(_vm._s(_vm.$t("Section.About")))])]),_c('li',[_c('a',{attrs:{"href":"/#FAQ"},on:{"click":function($event){_vm.toggled = false}}},[_vm._v(_vm._s(_vm.$t("Section.FaqTitle")))])]),_c('li',[_c('a',{attrs:{"href":"/#Contact"},on:{"click":function($event){_vm.toggled = false}}},[_vm._v(_vm._s(_vm.$t("Section.ContactTitle")))])]),_c('li',{on:{"click":function($event){_vm.toggled = false}}},[_c('div',{staticClass:"px-3 siteColor"},[(_vm.getCurrentDir == 'rtl')?_c('span',{staticClass:"cursor-pointer"},[_c('a',{key:'kr',staticStyle:{"font-family":"'Speda' !important"},on:{"click":function($event){return _vm.switchLocale('kr')}}},[_vm._v(_vm._s(_vm.$t("Language.Kirmanji")))])]):_vm._e(),(_vm.getCurrentDir == 'ltr')?_c('span',{staticClass:"cursor-pointer"},[_c('a',{key:'ku',staticStyle:{"font-family":"'Speda' !important"},on:{"click":function($event){return _vm.switchLocale('ku')}}},[_vm._v(_vm._s(_vm.$t("Language.Kurdish")))])]):_vm._e()])])])]),_c('nav',[_c('div',{staticClass:"logo grid font-bold",staticStyle:{"grid-template-columns":"auto auto auto"}},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":"/Images/logo.png","alt":"Logo"}})]),_c('ul',{staticClass:"desktopNav text-white hidden gap-10 lg:flex items-center text-lg",class:{
          'ml-5 pl-5': _vm.getCurrentDir == 'ltr',
          'mr-5 pr-5': _vm.getCurrentDir == 'rtl',
        },style:({ 'line-height': _vm.getCurrentDir == 'rtl' ? '36px' : '30px' })},[_c('li',[_c('a',{attrs:{"href":"/"},on:{"click":function($event){_vm.toggled = false}}},[_vm._v(_vm._s(_vm.$t("Section.Home")))])]),_c('li',[_c('a',{attrs:{"href":"/#About"},on:{"click":function($event){_vm.toggled = false}}},[_vm._v(_vm._s(_vm.$t("Section.About")))])]),_c('li',[_c('a',{attrs:{"href":"/#FAQ"},on:{"click":function($event){_vm.toggled = false}}},[_vm._v(_vm._s(_vm.$t("Section.FaqTitle")))])]),_c('li',[_c('a',{attrs:{"href":"/#Contact"},on:{"click":function($event){_vm.toggled = false}}},[_vm._v(_vm._s(_vm.$t("Section.ContactTitle")))])]),_c('li',[_c('div',{staticClass:"flex h-full px-3 siteColor items-center"},[(_vm.getCurrentDir == 'rtl')?_c('span',{staticClass:"cursor-pointer"},[_c('a',{key:'kr',staticStyle:{"font-family":"'Speda' !important"},on:{"click":function($event){return _vm.switchLocale('kr')}}},[_vm._v(_vm._s(_vm.$t("Language.Kirmanji")))])]):_vm._e(),(_vm.getCurrentDir == 'ltr')?_c('span',{staticClass:"cursor-pointer"},[_c('a',{key:'ku',staticStyle:{"font-family":"'Speda' !important"},on:{"click":function($event){return _vm.switchLocale('ku')}}},[_vm._v(_vm._s(_vm.$t("Language.Kurdish")))])]):_vm._e()])])])],1),_c('div',{staticClass:"grid font-bold",staticStyle:{"grid-template-columns":"auto auto"}},[_c('div',{staticClass:"hamburger block lg:hidden",class:{ toggled: _vm.toggled },on:{"click":function($event){_vm.toggled = !_vm.toggled}}},[_c('span'),_c('span'),_c('span')])])]),_c('sideBar'),_c('router-view',{class:_vm.getCurrentDir,style:({
      direction: _vm.getCurrentDir,
      'text-align': _vm.getCurrentDir == 'rtl' ? 'right' : 'left',
    }),attrs:{"dir":_vm.getCurrentDir}}),_c('footer',{staticClass:"block"},[_c('div'),_vm._m(0),_c('p',{staticClass:"text-center font-bold"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('Copyright'))}})])]),_vm._m(1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"social mx-auto"},[_c('div',{staticClass:"text-2xl"},[_c('a',{attrs:{"href":"https://www.facebook.com/agoravisioncom","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-facebook"})]),_c('a',{attrs:{"href":"https://www.instagram.com/agoravisioncom/","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-instagram"})]),_c('a',{attrs:{"href":"https://www.linkedin.com/company/agoravision/","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-linkedin"})]),_c('a',{attrs:{"href":"https://www.youtube.com/channel/UCcGXMcvYue1apphdDW4jtZA/featured","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-youtube"})]),_c('a',{attrs:{"href":"https://web.telegram.org/k/#/im?p=@agoravision","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-telegram"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rounded-md shadow-lg font-bold text-xl text-blue-500 text-center",attrs:{"id":"backToTop"}},[_c('a',{attrs:{"href":"#app"}},[_c('i',{staticClass:"fa fa-caret-up"})])])
}]

export { render, staticRenderFns }