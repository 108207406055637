<style scoped>
.VueCarousel-slide {
  visibility: visible;
}
.VueCarousel {
  width: 100%;
  height: 100%;
}

.accordion-item {
  transition: border-color 0.3s;
}

.accordion-title:hover {
  background-color: #f0f0f0;
}
</style>

<template>
  <div>
    <div class="hidden sm:block" style="direction: ltr !important">
      <carousel
        :perPage="1"
        :paginationPosition="'bottom-overlay'"
        :navigationEnabled="true"
        navigationNextLabel="<i class='fa fa-arrow-right'></i>"
        navigationPrevLabel="<i class='fa fa-arrow-left'></i>"
      >
        <slide v-for="i in 3" :key="'slide' + i">
          <div class="oneSlide">
            <img :src="`/Images/${Lang=='KR'?'kmj-':''}Slider-` + i + `-3.jpg`" alt="Desktop Slide" />
          </div>
        </slide>
      </carousel>
      <!-- This is the desktop carousel -->
    </div>
    <div class="block sm:hidden" style="direction: ltr !important">
      <carousel
        :perPage="1"
        :paginationPosition="'bottom-overlay'"
        :navigationEnabled="true"
        navigationNextLabel="<i class='fa fa-arrow-right'></i>"
        navigationPrevLabel="<i class='fa fa-arrow-left'></i>"
      >
        <slide v-for="i in 3" :key="'m_slide' + i">
          <div class="oneSlide">
            <img
              :src="`/Images/${Lang=='KR'?'kmj-':''}Mobile-Slider-` + i + `.jpg`"
              alt="Mobile Slide"
            />
          </div>
        </slide>
      </carousel>
    </div>

    <div id="About" class="Company About">
      <div class="wrapper shadow-lg rounded-md my-2">
        <div>
          <h2 class="text-2xl font-bold mb-4">
            {{ $t("Section.CompanyTitle") }}
          </h2>
          <div class="text-lg" v-html="$t('Section.CompanyText')"></div>
          <br />
        </div>
        <div>
          <img src="/Images/About-Clip-Art.png" alt="The Company" />
        </div>
      </div>
    </div>

    <div id="books" class="Books">
      <div v-if="filteredBooks">
        <br /><br />
        <div class="wrapper shadow-lg rounded-md my-5">
          <h2 class="text-2xl font-bold mb-4">
            {{ $t("Section.bookSample") }}
          </h2>
          <br />
          <no-ssr>
            <div class="newsContent md:flex gap-5">
              <carousel
                :rtl="Lang == 'KU'"
                :perPageCustom="[
                  [320, 2],
                  [667, 3],
                  [1200, 6],
                  [1921, 8],
                ]"
                :loop="false"
              >
                <slide v-for="b in filteredBooks" :key="b.id">
                  <div
                    class="singleContent overflow-hidden rounded-md mb-3 mx-3 md:mb-0"
                  >
                    <img
                      placeholder
                      loading="lazy"
                      :src="b.img"
                      :alt="b['Title' + Lang]"
                    />
                  </div>
                  <br />
                  <div class="info px-3">
                    <h3 class="text-md font-bold text-sm siteColor text-center">
                      <span class="block mb-2" style="overflow: hidden">{{
                        b["Title" + Lang]
                      }}</span>
                    </h3>
                  </div>
                </slide>
              </carousel>
            </div>
          </no-ssr>
          <br />
          <div class="flex text-center flex-col sm:flex-row gap-5">
            <a
              href="https://bit.ly/3ItpEcf"
              class="agora-gold py-3 px-3 text-gray-100 rounded-md"
              target="_blank"
            >
              {{ $t("Section.Google") }}
            </a>
            <a
              href="https://amzn.to/47n5zOE"
              class="agora-gold py-3 px-3 text-gray-100 rounded-md"
              target="_blank"
            >
              {{ $t("Section.Amazon") }}
            </a>
          </div>
        </div>
      </div>
      <div v-if="!filteredBooks">
        <br /><br />
        <div class="wrapper shadow-lg rounded-md my-5">
          <h2 class="text-2xl font-bold mb-4">{{ $t("Section.News") }}</h2>
          <div class="waiting">
            <div>
              <video playsinline autoplay muted loop class="mx-auto">
                <source src="Images/loading.gif" type="video/gif" />
                Your browser does not support the video tag.
              </video>
              <h3>{{ $t("please") }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />
    <div id="FAQ" class="FAQ">
      <div class="wrapper my-2">
        <h2 class="text-3xl font-bold mb-4">
          {{ $t("Section.FaqTitle") }}
        </h2>
        <br />
        <div class="accordion">
          <div
            v-for="(item, index) in filteredFaqs"
            :key="item.id"
            @click="toggleAccordion(index)"
            class="accordion-item border rounded-md mb-2"
          >
            <div
              class="accordion-title cursor-pointer px-4 py-2 flex justify-between items-center"
            >
              <div
                v-html="item['Title' + Lang]"
                class="siteColor text-lg font-bold py-3"
              ></div>
              <svg
                class="w-4 h-4 transform transition-transform duration-300"
                :class="{ 'rotate-180': activeIndex === index }"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path fill="currentColor" d="M7 10l5 5 5-5z" />
              </svg>
            </div>
            <div
              v-show="activeIndex === index"
              class="accordion-content px-4 py-2"
            >
              <div v-html="item['Content' + Lang]" class="py-3"></div>
            </div>
          </div>
        </div>
        <br />
        <router-link
          to="faq"
          class="agora-gold py-3 px-3 text-gray-100 rounded-md"
          >{{ $t("Section.allFaq") }}</router-link
        >
      </div>
    </div>

    <div id="Contact" class="Contact">
      <div class="wrapper shadow-lg rounded-md my-5">
        <div>
          <form @submit.prevent="sendEmail" ref="form" id="contactForm">
            <h2 class="text-2xl font-bold mb-2">
              {{ $t("Section.ContactTitle") }}
            </h2>

            <div class="aboveForm">
              <p>
                {{ $t("ContactForm.Heading") }}
                <br />
                <br />
                <span>{{ $t("ContactForm.subheading") }}</span>
                <br />
              </p>

              <div class="text-xs">
                <br />
                <p class="font-bold" v-html="$t('ContactForm.working')"></p>
                <br />
                <h3
                  class="font-bold text-lg siteColor"
                  v-html="$t('ContactForm.Iraq.name')"
                ></h3>
                <br />
                <span class="text-sm" v-html="$t('ContactForm.Iraq.address')">
                </span>

                <br />
                <span class="text-sm font-bold">
                  <a href="tel:009647702000545">{{
                    $t("ContactForm.PhoneOne")
                  }}</a>
                  |
                  <a href="tel:009647502000545">{{
                    $t("ContactForm.PhoneTwo")
                  }}</a>
                </span>
                <br />
                <br />
                <h3
                  class="font-bold text-lg siteColor"
                  v-html="$t('ContactForm.Canada.name')"
                ></h3>
                <br />
                <span class="text-sm" v-html="$t('ContactForm.Canada.address')">
                </span>
                <br />
                <span class="text-sm font-bold">
                  <a href="tel:0017767781476">{{
                    $t("ContactForm.PhoneCanada")
                  }}</a>
                </span>
                <br />
              </div>

              <br />
              <p>{{ $t("ContactForm.Sub") }}</p>
            </div>
            <label for="user_name"> {{ $t("ContactForm.Name") }} </label>
            <input
              required
              v-model="contact.name"
              type="text"
              name="user_name"
              class="block w-full h-8 rounded shadow-lg my-2 px-2 outline-none transition hover:shadow-md"
            />
            <label for="user_email">{{ $t("ContactForm.Email") }} </label>
            <input
              required
              v-model="contact.mail"
              type="email"
              name="user_email"
              class="block w-full h-8 rounded shadow-lg my-2 px-2 outline-none transition hover:shadow-md"
            />
            <label for="user_phone"> {{ $t("ContactForm.Phone") }} </label>
            <input
              required
              v-model="contact.phone"
              type="tel"
              name="user_phone"
              class="block w-full h-8 rounded shadow-lg my-2 px-2 outline-none transition hover:shadow-md"
            />
            <label for="message" class="block">
              {{ $t("ContactForm.Message") }}
            </label>
            <textarea
              required
              v-model="contact.message"
              name="message"
              class="block w-full h-24 rounded shadow-lg my-2 px-2 outline-none transition hover:shadow-md"
            ></textarea>
            <button
              class="mt-3 block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition"
            >
              {{ $t("Send") }}
            </button>
          </form>
        </div>
        <div class="w-50">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3245.5323177804235!2d45.432587515609875!3d35.56525094393079!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40002dc3c9af9dcb%3A0x1382709528a56a1e!2zQWdvcmEgVmlzaW9uINim2Kfar9uG2LHYpyDapNuM2pjZhg!5e0!3m2!1sen!2siq!4v1657790412965!5m2!1sen!2siq"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Service ID service_it8ziaq
//Template ID template_fro9gmk
//user ID user_Mhdpo0N7SP8VnDjdVXPda

import { Carousel, Slide } from "vue-carousel";
import emailjs from "@emailjs/browser";
import { mapGetters } from "vuex";
import NoSSR from 'vue-no-ssr'
export default {
  computed: mapGetters([
    "getCurrentLocale",
    "getCurrentDir",
    "getBooks",
    "getFaqs",
    "Lang",
  ]),

  name: "Home",
  components: {
    Carousel,
    'no-ssr': NoSSR,
    Slide,
  },
  data() {
    return {
      activeIndex: null,
      filteredBooks: null,
      filteredFaqs: null,
      contact: {
        name: null,
        mail: null,
        phone: null,
        message: null,
      },
    };
  },
  methods: {
    toggleAccordion(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null;
      } else {
        this.activeIndex = index;
      }
    },
    sendEmail() {
      this.$loading(true);
      emailjs
        .sendForm(
          "service_it8ziaq",
          "template_fro9gmk",
          this.$refs.form,
          "user_Mhdpo0N7SP8VnDjdVXPda"
        )
        .then(
          (result) => {
            console.log(result);
            this.contact = {
              name: null,
              mail: null,
              phone: null,
              message: null,
            };
            this.$loading(false);
            this.$vToastify.success(
              this.getCurrentLocale == "ku"
                ? "ئیمەیڵ بە سەرکەوتوویی نێردرا."
                : "Email was sent successfully.",
              this.getCurrentLocale == "ku" ? "سەرکەوتن!" : "Success!"
            );
          },
          (error) => {
            console.log(error);
            this.$loading(false);
            this.$vToastify.error(
              this.getCurrentLocale == "ku"
                ? "تکایە پەیوەندی بکە بە سەرپەرشتیاری وێب سایتەوە."
                : "Please contact website administrator.",
              this.getCurrentLocale == "ku"
                ? "هەڵەیەک ڕوویدا."
                : "An error occurred."
            );
          }
        );
    },
  },
  mounted() {
  let faqFillInterval = setInterval(() => {
    this.filteredFaqs = this.getFaqs.filter((item) => item.Enabled).slice(0, 3);

    if (this.filteredFaqs.length > 0) {
      clearInterval(faqFillInterval);
    } else {
      console.log("Retrieving faqs . . .");
    }
  }, 500);

  let bookFillInterval = setInterval(() => {
    this.filteredBooks = this.getBooks.filter((item) => item.Enabled).slice(0, 10);

    if (this.filteredBooks.length > 0) {
      clearInterval(bookFillInterval);
    } else {
      console.log("Retrieving books . . .");
    }
  }, 500);
}
,
};
</script>
