<template>
  <div>
    <div
      class="banner pb-5 agora-gold"
      
    ></div>
    <div class="notFound py-5 my-5">
      <div class="imageContainer" >
        <img src="/Images/notFound.png" alt="Not Found" class="w-96 mx-auto" />
        <br>
        <h3 class="text-2xl" >{{$t('404')}}</h3>
        <br>
        <h4 v-html="$t('notFound')">
         
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>